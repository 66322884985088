// src/App.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import Modal from "react-modal";

function App() {
  const [feedback, setFeedback] = useState({
    name: "",
    email: "",
    overallRating: 0,
    lengthRating: 0,
    Comprehensibility: 0,
    Aptness: 0,
    Depth: 0,
    Authenticity: 0,
    Timeliness: 0,
    commentName: "",
    commentReview: "",
    sourceArticle: "",
    pageNo: 0,
    journalRelease: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fullReviewText, setFullReviewText] = useState("");
  const [latestReviews, setLatestReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const fetchLatestReviews = async () => {
    try {
      const response = await axios.get(
        `https://cjfeedbackapi.computerjagat.org/ojs_api/latest-reviews?page=${currentPage}`
      );
      setLatestReviews(response.data);
    } catch (error) {
      console.error("Error fetching latest reviews: ", error);
    }
  };
  useEffect(() => {
    fetchLatestReviews();
  }, [currentPage]);

  useEffect(() => {
    // Calculate the average rating
    const averageRating =
      (feedback.lengthRating +
        feedback.Comprehensibility +
        feedback.Aptness +
        feedback.Depth +
        feedback.Authenticity +
        feedback.Timeliness) /
      6;

    // Update the "Average" stars
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      overallRating: Math.round(averageRating),
    }));
  }, [
    feedback.lengthRating,
    feedback.Comprehensibility,
    feedback.Aptness,
    feedback.Depth,
    feedback.Authenticity,
    feedback.Timeliness,
  ]);

  useEffect(() => {
    // Parse URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const sourceArticle = urlParams.get("SOURCE_ARTICLE");
    const pageNo = urlParams.get("PAGE_NO");
    const journalRelease = urlParams.get("JOURNAL_RELEASE");

    // Set values in feedback state
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      sourceArticle: sourceArticle || "",
      pageNo: pageNo ? parseInt(pageNo, 10) : 0,
      journalRelease: journalRelease || "",
    }));
  }, []);
  const openModal = (review) => {
    setFullReviewText(review);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFeedback({ ...feedback, [name]: value });
  };

  const handleStarClick = (field, rating) => {
    if (field !== "overallRating") {
      setFeedback({ ...feedback, [field]: rating });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://cjfeedbackapi.computerjagat.org/ojs_api/submit-feedback",
        feedback
      );
      console.log(response.data);
      setFeedback({
        name: "",
        email: "",
        overallRating: 0,
        lengthRating: 0,
        Comprehensibility: 0,
        Aptness: 0,
        Depth: 0,
        Authenticity: 0,
        Timeliness: 0,
        commentName: "",
        commentReview: "",
        sourceArticle: feedback.sourceArticle,
        pageNo: feedback.pageNo,
        journalRelease: feedback.journalRelease,
      });
      fetchLatestReviews(); // Refresh latest reviews after submission
    } catch (error) {
      console.error("Error submitting feedback: ", error);
    }
  };

  const handleSeeMoreClick = async (id) => {
    try {
      const response = await axios.get(
        `https://cjfeedbackapi.computerjagat.org/ojs_api/full-review/${id}`
      );
      const fullReview = response.data[0].commentReview;
      openModal(fullReview);
    } catch (error) {
      console.error("Error fetching full review: ", error);
    }
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handleLoadPrevious = () => {
    setCurrentPage((prevPage) => (prevPage == 1 ? 1 : prevPage - 1));
  };

  return (
    <div className="App">
      <h1>Feedback Form</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Full Name:{" "}
          <input
            type="text"
            name="name"
            value={feedback.name}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Email:{" "}
          <input
            type="email"
            name="email"
            value={feedback.email}
            onChange={handleInputChange}
            required
          />
        </label>
        <br />
        <div>
          <label>Overall:</label>
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              onClick={() => handleStarClick("overallRating", star)}
            >
              {star <= feedback.overallRating ? "★" : "☆"}
            </span>
          ))}
        </div>
        <div>
          <label>Length:</label>
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              onClick={() => handleStarClick("lengthRating", star)}
            >
              {star <= feedback.lengthRating ? "★" : "☆"}
            </span>
          ))}
        </div>
        <div>
          <label>Comprehensibility:</label>
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              onClick={() => handleStarClick("Comprehensibility", star)}
            >
              {star <= feedback.Comprehensibility ? "★" : "☆"}
            </span>
          ))}
        </div>
        <div>
          <label>Aptness:</label>
          {[1, 2, 3, 4, 5].map((star) => (
            <span key={star} onClick={() => handleStarClick("Aptness", star)}>
              {star <= feedback.Aptness ? "★" : "☆"}
            </span>
          ))}
        </div>
        <div>
          <label>Depth:</label>
          {[1, 2, 3, 4, 5].map((star) => (
            <span key={star} onClick={() => handleStarClick("Depth", star)}>
              {star <= feedback.Depth ? "★" : "☆"}
            </span>
          ))}
        </div>
        <div>
          <label>Authenticity:</label>
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              onClick={() => handleStarClick("Authenticity", star)}
            >
              {star <= feedback.Authenticity ? "★" : "☆"}
            </span>
          ))}
        </div>
        <div>
          <label>Timeliness:</label>
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              onClick={() => handleStarClick("Timeliness", star)}
            >
              {star <= feedback.Timeliness ? "★" : "☆"}
            </span>
          ))}
        </div>
        <label>
          {/* Comment Name: */}
          <input
            type="hidden"
            // type="text"
            name="commentName"
            // value={feedback.commentName}
            value=" "
            onChange={handleInputChange}
            maxLength="150"
            required
          />
        </label>
        <br />
        <label>Your Review: {""}</label>

        <textarea
          name="commentReview"
          value={feedback.commentReview}
          onChange={handleInputChange}
          maxLength="5000"
          rows="6" // Set the number of visible rows
          style={{
            minHeight: "100px",
            width: "100%",
            padding: "8px",
            boxSizing: "border-box",
          }}
          required
        />
        <br />
        <input
          type="hidden"
          name="sourceArticle"
          value={feedback.sourceArticle}
        />
        <input type="hidden" name="pageNo" value={feedback.pageNo} />
        <input
          type="hidden"
          name="journalRelease"
          value={feedback.journalRelease}
        />
        <button className="button_loadmore" type="submit">
          Submit Feedback
        </button>
      </form>

      <h2>Latest Reviews</h2>
      <div>
        {latestReviews.map((review, index) => (
          <div key={index}>
            <p>
              <strong>{review.name}</strong> - Overall Rating:{" "}
              {review.overallRating}
            </p>
            <p>{review.shortReview}</p>
            <button onClick={() => handleSeeMoreClick(review.id)}>
              See More...
            </button>
          </div>
        ))}
      </div>
      {currentPage > 1 && (
        <>
          <button className="button_loadmore" onClick={handleLoadPrevious}>
            Load Previous
          </button>
          <br></br>
        </>
      )}
      {latestReviews[0]?.TotalRowCount / 5 >= currentPage && (
        <>
          <button className="button_loadmore" onClick={handleLoadMore}>
            Load More
          </button>
        </>
      )}

      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Full Review Modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              width: "60%",
              margin: "auto",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              padding: "20px",
            },
          }}
        >
          <h2>Full Review</h2>
          <p>{fullReviewText}</p>
          <button className="button_loadmore" onClick={closeModal}>
            Close
          </button>
        </Modal>
      </div>
    </div>
  );
}
export default App;
